<template>
  <div class="p-grid">
    <div class="p-col-12"></div>
  </div>
</template>

<script setup>
import { onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

onMounted(() => {
  watchEffect(() => {
    if (store.state.initialized) {
      window.Intercom('show')
    }
  })
})
</script>

<style scoped></style>
